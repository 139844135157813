import type { RouteRecordRaw } from 'vue-router';

const constantRoutes: RouteRecordRaw[] = [
  {
    path: '/home',
    component: () => import('/@/views/home/index.vue'),
    name: 'home',
    meta: { title: '首页' }, // 远望通用首页
  },
  {
    path: '/test-home',
    component: () => import('/@/views/test-home/index.vue'),
    name: 'test-home',
    meta: { title: '首页' }, // 由于远望没有测试环境，通过引入不同的http拦截器以支持预发布环境测试验证
  },
  {
    path: '/home-sdte',
    component: () => import('/@/views/home-sdte/index.vue'),
    name: 'home-sdte',
    meta: { title: '首页' }, // 天鹅大屏
  },
  {
    path: '/test-home-sdte',
    component: () => import('/@/views/test-home-sdte/index.vue'),
    name: 'test-home-sdte',
    meta: { title: '首页' }, // 由于远望没有测试环境，通过引入不同的http拦截器以支持预发布环境测试验证
  },
  {
    path: '/acresMap',
    component: () => import('/@/views/acresMap/index.vue'),
    name: 'acres',
    meta: { title: '亩数' }, // 亩数轨迹点图
  },
  {
    path: '/acresDetail',
    component: () => import('/@/views/acresDetail/index.vue'),
    name: 'acresDetail',
    meta: { title: '亩数详情', keepAlive: true }, // 亩数详情
  },
  {
    path: '/locus-sdte',
    component: () => import('/@/views/locus-sdte/index.vue'),
    name: 'locus-sdte',
    meta: { title: '轨迹' }, // 天鹅亩数轨迹
  },
  //test- 为远望小程序使用到的页面
  {
    path: '/test-device',
    component: () => import('/@/views/test-device/index.vue'),
    name: 'test-device',
    meta: { title: '设备详情mp' },
  },
  {
    path: '/test-workInfo',
    component: () => import('/@/views/test-workInfo/index.vue'),
    name: 'test-workInfo',
    meta: { title: '工况数据' },
  },
  {
    path: '/test-acresDetail',
    component: () => import('/@/views/test-acresDetail/index.vue'),
    name: 'test-acresDetail',
    meta: { title: '亩数统计', keepAlive: true },
  },
  {
    path: '/test-acresMap',
    component: () => import('/@/views/test-acresMap/index.vue'),
    name: 'test-acresMap',
    meta: { title: '亩数详情' },
  },
  {
    path: '/test-reports',
    component: () => import('/@/views/test-reports/index.vue'),
    name: 'test-reports',
    meta: { title: '日月报' },
  },
  {
    path: '/',
    name: 'Root',
    redirect: '/home',
    meta: {
      title: 'Root',
    },
  },
];

// /**
//  * 基础路由
//  * @type { *[] }
//  */
// export const constantRouterMap = [];

export default constantRoutes;
